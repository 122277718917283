import React from 'react'

const Cementos = () => {
    return (
        <>
            <h1>Cementos en España</h1>

            <p>En España, el mercado de los cementos es sólido y dinámico, con una industria cementera consolidada y un gran número de fabricantes y proveedores. El cemento es un material fundamental en la construcción y se utiliza ampliamente en proyectos residenciales, comerciales e industriales en todo el país.</p>

            <h2>Tipos de cemento en España</h2>

            <p>En España, se utilizan diferentes tipos de cemento para satisfacer las diversas necesidades de la construcción. Algunos de los tipos de cemento más comunes son:</p>

            <ol>
                <li>Cemento Portland: El Cemento Portland es el tipo de cemento más utilizado en España y en el mundo. Se clasifica en varios subtipos según las propiedades y características requeridas.</li>
                <li>Cemento Blanco: El Cemento Blanco es apreciado por su color claro y su apariencia estética. Se utiliza en proyectos donde se busca un aspecto visualmente atractivo.</li>
                <li>Cemento de Alta Resistencia: Este tipo de cemento se caracteriza por su mayor capacidad de carga y resistencia a la compresión.</li>
                <li>Cemento Puzolánico: El Cemento Puzolánico contiene adiciones puzolánicas que mejoran sus propiedades. Es apreciado por su alta resistencia a los sulfatos y durabilidad en ambientes agresivos.</li>
                <li>Cemento Especializado: Además de los tipos mencionados anteriormente, existen cementos especializados que se adaptan a necesidades específicas.</li>
            </ol>

            <h2>Preguntas frecuentes sobre los cementos</h2>

            <h3>¿Cuáles son los 5 tipos de cemento?</h3>
            <p>En España, existen varios tipos de cemento utilizados en la construcción, incluyendo el Cemento Portland, el Cemento Blanco, el Cemento de Alta Resistencia, el Cemento Puzolánico y los Cementos Especializados.</p>

            <h3>¿Cuáles son los tipos de cemento que existen?</h3>
            <p>En España, se utilizan diversos tipos de cemento, entre ellos el Cemento Portland, el Cemento Blanco, el Cemento de Alta Resistencia, el Cemento Puzolánico y los Cementos Especializados.</p>

            <h3>¿Cuál es el tipo de cemento más utilizado?</h3>
            <p>El tipo de cemento más utilizado en España es el Cemento Portland. Este tipo de cemento es versátil y se adapta a una amplia gama de aplicaciones en la construcción.</p>

            <h3>¿Cuál es el cemento de mejor calidad?</h3>
            <p>En España, existen varios fabricantes de cemento que ofrecen productos de alta calidad. La elección del cemento de mejor calidad depende de las necesidades y requisitos específicos de cada proyecto.</p>

            <h2>+ info sobre cementos</h2>

            <p>El hormigón impreso es un tipo de hormigón decorativo que se crea mediante la impresión de un diseño en la superficie del hormigón fresco. El diseño puede ser cualquier cosa, desde patrones simples hasta imágenes complejas. El hormigón impreso es una opción popular para suelos, paredes y techos.</p>

            <p>El microcemento es un material de construcción fino, de un espesor de 2 a 3 mm, que se utiliza para revestir superficies interiores y exteriores. El microcemento es una mezcla de cemento, áridos finos, resinas y pigmentos. Se puede aplicar sobre cualquier superficie, incluyendo hormigón, baldosas, madera y metal. El microcemento es un material versátil que se puede utilizar para crear una variedad de acabados, desde superficies lisas hasta superficies rugosas.</p>

            <p>El cemento es un material de construcción que se utiliza para fabricar hormigón. El cemento está hecho de una mezcla de piedra caliza, arcilla y sílice que se calienta hasta altas temperaturas. El cemento es un material esencial para la construcción de edificios, carreteras y puentes.</p>

            <p>El concreto es un material compuesto formado por un aglomerante, agregados finos y agregados gruesos. El aglomerante es generalmente cemento Portland, que es un polvo fino que se endurece cuando se mezcla con agua. Los agregados finos son arena y los agregados gruesos son grava. El concreto se utiliza para construir una amplia variedad de estructuras, incluyendo edificios, carreteras, puentes y presas.</p>

            <p>El precio del cemento varía en función de la calidad, la marca y el lugar de compra. En España, el precio del cemento por bolsa de 50 kg oscila entre los 10 y los 20 euros.</p>

            <p>El cemento blanco es un tipo de cemento que se utiliza para crear hormigón de color blanco. El cemento blanco está hecho de una mezcla de piedra caliza blanca, arcilla y sílice que se calienta hasta altas temperaturas. El cemento blanco es un material esencial para la construcción de edificios y otras estructuras que requieren un aspecto blanco.</p>

            <p>El cemento Portland es el tipo de cemento más común utilizado en la construcción. El cemento Portland está hecho de una mezcla de piedra caliza, arcilla y sílice que se calienta hasta altas temperaturas. El cemento Portland es un material esencial para la construcción de edificios, carreteras y puentes.</p>

            <p>El cemento pulido es un tipo de hormigón que se ha pulido para crear una superficie lisa y brillante. El cemento pulido es una opción popular para suelos, paredes y techos. El cemento pulido es un material duradero y resistente a las manchas.</p>

            <p>El mortero autonivelante es un tipo de mortero que se nivela automáticamente. El mortero autonivelante se utiliza para nivelar superficies de hormigón, baldosas y madera. El mortero autonivelante es un material fácil de usar y que se seca rápidamente.</p>

            <p>El mortero de cemento es una mezcla de cemento, arena y agua que se utiliza para unir ladrillos, baldosas y otros materiales de construcción. El mortero de cemento es un material esencial para la construcción de paredes, suelos y techos.</p>

        </>

    )
}

export default Cementos