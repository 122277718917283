import logo from './logo.svg';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Cementos from './components/Cementos';

function App() {
  return (
    <>
      <Routes>
        <Route path="cementos" exact element={<Cementos />} />
        <Route path="/" exact element={<Home />} />
      </Routes>
      
    </>
  );
}

export default App;
