import React from 'react'
import Header from './Header'
import Menu from './Menu'

const Home = () => {
    return (
        <>
            <Header />
            <Menu />
        </>
    )
}

export default Home