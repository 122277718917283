import React from 'react'
import './Menu.css'

const Menu = () => {
    return (
        <>
            <div className="contact">
                <h3 className="header-menu">VENTAS</h3>
                <h4 className="content-menu">ATENCIÓN COMERCIAL: <a href="tel:+34918169497">918169497</a></h4>
                <h3 className="header-menu">LOGÍSTICA</h3>
                <a href='https://www.google.es/maps/dir//MATCENTER+EUROPE+S.L.U.,+Cam.+de+Humanes+a+Torrej%C3%B3n+de+Velasco,+6,+28991+Torrej%C3%B3n+de+la+Calzada,+Madrid/@40.2079961,-3.8642629,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0xd4190485fc6e097:0x95dd97c3121f41f9!2m2!1d-3.7939569!2d40.2080505!3e0?hl=es'><h4 className="content-menu">CALLE REAL 1-A <br></br> TORREJÓN DE LA CALZADA <br></br> (29991) MADRID <br></br> (junto a los desguaces)</h4></a>
            </div>
            <a href='https://www.google.es/maps/dir//MATCENTER+EUROPE+S.L.U.,+Cam.+de+Humanes+a+Torrej%C3%B3n+de+Velasco,+6,+28991+Torrej%C3%B3n+de+la+Calzada,+Madrid/@40.2079961,-3.8642629,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0xd4190485fc6e097:0x95dd97c3121f41f9!2m2!1d-3.7939569!2d40.2080505!3e0?hl=es'><div className="mapa"></div></a>
            <div className='familias'>
                <ul>
                    <li>CONTENEDORES PARA ESCOMBRO, GESTIÓN DE RESÍDUOS</li>
                    <li>BIOCOMBUSTIBLES: PELLET, HUESO DE ACEITUNA, LEÑA</li>
                    <li>AZULEJOS</li>
                    <li>GRES</li>
                    <li>PLADUR</li>
                    <li>PIEDRA NATURAL</li>
                    <li>ÁRIDOS DECORATIVOS</li>
                    <li>LADRILLOS</li>
                    <li>RASILLONES</li>
                    <li>BLOQUES</li>
                    <li>BORDILLOS</li>
                    <li>VIGAS</li>
                    <li>HIERROS</li>
                    <li>MALLAZOS</li>
                    <li>YESOS</li>
                    <li>ESCAYOLAS</li>
                    <a href='cementos'><li>CEMENTOS</li></a>
                    <a href='/cementos' className='grupo'>
                        <img src='./cementos.jpg' alt='cementos' className='imagenes-familias' />
                        <p className='texto-grupo'>+ info</p>
                    </a>


                    <li>MORTEROS</li>
                    <li>ADITIVOS</li>
                    <li>AISLAMIENTOS</li>
                    <li>PERFILERÍAS</li>
                    <li>PLÁSTICOS</li>
                    <li>MURFOS</li>
                    <li>MASILLAS</li>
                    <li>CANALES</li>
                    <li>VARILLAS</li>
                    <li>AISLANTES</li>
                    <li>LANA DE ROCA</li>
                    <li>TELAS ASFÁLTICAS</li>
                    <li>GRESITES</li>
                    <li>PERFILES</li>
                    <li>ONDULINE</li>
                    <li>PLACAS</li>
                    <li>ARENAS</li>
                    <li>GRAVAS</li>
                    <li>CANALETAS</li>
                    <li>MANTILLO</li>
                    <li>CALZOS</li>
                    <li>ZUNCHOS</li>
                    <li>TIERRA VEGETAL</li>
                    <li>SISTEMAS DE NIVELACIÓN</li>
                    <li>PASTAS DE AGARRE</li>
                    <li>TECHO DESMONTABLE</li>
                    <li>REFRACTARIOS</li>
                    <li>PIZARRAS</li>
                    <li>TERMOARCILLA</li>
                    <li>BREA</li>
                    <li>BOVEDILLAS</li>
                    <li>REMATES</li>
                    <li>MIRAS</li>
                    <li>MAESTRAS</li>
                    <li>OMEGAS</li>
                    <li>PELDAÑOS</li>
                    <li>PORESPAN</li>
                    <li>PASAMANOS</li>
                    <li>CUBREMUROS</li>
                    <li>CANALIZACIONES</li>
                    <li>RESINAS</li>
                    <li>LIMPIADORES</li>
                    <li>TUBOS</li>
                    <li>AUTONIVELANTES</li>
                    <li>ESPUMAS</li>
                    <li>CARTÓN</li>
                    <li>TERRAZOS</li>
                    <li>CUÑAS</li>
                    <li>POLIURETANO</li>
                    <li>FIBRAS</li>
                    <li>CINTAS</li>
                    <li>GUARDAVIVOS</li>
                    <li>LANA MINERAL</li>
                    <li>IMPERMEABILIZACIÓN</li>
                    <li>TEJAS</li>
                    <li>PEGAMENTOS</li>
                    <li>PINTURAS</li>
                </ul>
            </div>
        </>
    )
}

export default Menu